import type { ReactNode } from "react";
import type { Dossier, TypeDossier } from "models";
import { Box, Stack, Typography } from "@mui/material";
import { TypeDossierIcon } from "components/TypeDossierIcon";

interface DossierTypeIconRectangleProps {
  type: TypeDossier;
}

export function DossierTypeIconRectangle({
  type,
}: Readonly<DossierTypeIconRectangleProps>): ReactNode {
  return (
    <Box
      component="span"
      sx={{
        backgroundColor: `${type.toLowerCase()}.main`,
        display: "inline-block",
        lineHeight: 0,
        p: 0.25,
        m: 0,
        borderRadius: 1,
      }}
      color="white">
      <TypeDossierIcon type={type} />
    </Box>
  );
}

interface DossierChipProps {
  dossier: Dossier;
}

function DossierChip({ dossier }: Readonly<DossierChipProps>): ReactNode {
  return (
    <Stack
      component="span"
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{ whiteSpace: "nowrap" }}>
      <DossierTypeIconRectangle type={dossier.type} />
      <Typography sx={{ color: `${dossier.type.toLowerCase()}.dark`, fontWeight: 500 }}>
        {(dossier.reference ?? dossier.type).toUpperCase()}
      </Typography>
    </Stack>
  );
}

export default DossierChip;
