import HomePage from "pages/Home/HomePage";
import { Root } from "components/Template";
import { routesConfig } from "config/app-config";
import { createBrowserRouter } from "react-router-dom";
import AdressesPage from "pages/PageLists/AdressesPage";
import { ChantierDetailsWithErrorBoundary } from "pages/Chantier/ChantierDetailsWithErrorBoundary";
import { DossierWithErrorBoundary } from "pages/Chantier/components/DossierWithErrorBoundary";
import { NouveauChantierWithErrorBoundary } from "pages/NouveauChantier/NouveauChantierWithErrorBoundary";
import ModifieAdressePageWithErrorBoundary from "pages/ModifieAdresse/ModifieAdressePage";
import { BaseErrorPage, NotFoundErrorPage } from "utils/errorHandling";

export const authenticatedRouter = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <BaseErrorPage />,
    children: [
      {
        path: routesConfig.home.path,
        element: <HomePage />,
      },
      {
        path: routesConfig.nouveauChantier.path,
        element: <NouveauChantierWithErrorBoundary />,
      },
      {
        path: routesConfig.chantier.path,
        element: <ChantierDetailsWithErrorBoundary />,
        children: [
          {
            path: routesConfig.chantierDossier.path,
            element: <DossierWithErrorBoundary />,
          },
        ],
      },
      {
        path: routesConfig.adresses.path,
        element: <AdressesPage />,
      },
      {
        path: routesConfig.modifierAdresse.path,
        element: <ModifieAdressePageWithErrorBoundary />,
      },
      {
        path: routesConfig.any.path,
        element: <NotFoundErrorPage />,
      },
    ],
  },
]);
