import type { Avis } from "models";
import { Endpoints } from "enums";
import { opaleApiClient } from "./apiClients";

/**
 * Retourne un Avis à partir de son id
 * @returns Un Avis
 */
async function getAvisById(id: string): Promise<Avis> {
  const url = `/${Endpoints.AVIS}/${id}`;
  return opaleApiClient.get<Avis, Avis>(url);
}

/**
 * Retourne tous les Avis
 * @returns Un tableau d'Avis
 */
async function getAllAvis(): Promise<Array<Avis>> {
  const url = `/${Endpoints.AVIS}`;
  return opaleApiClient.get<Array<Avis>, Array<Avis>>(url);
}

export const avisService = {
  getAvisById,
  getAllAvis,
};
