import { type ReactNode } from "react";
import { Link, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import type { DossierEau } from "models";
import { CourrierDefinitions } from "models";
import { FormSection } from "components/Layout";
import { withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { useDossier, useChantier } from "providers";
import DossierSections from "../DossierSections";
import { dossierEauSections } from "../routes/dossiersSectionsDefinitions";
import { filesService } from "services";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DocumentGenerationButton } from "../DocumentGenerationButton";
import { useDestinataireCopie } from "pages/Chantier/hooks/useDestinataireCopie";

function DossierEauFormCourriers(): ReactNode {
  const { chantier, isLoading: isChantierLoading } = useChantier();
  const { dossier, isLoading: isDossierLoading = false } = useDossier<DossierEau>();
  const { destinataireCopie, patchDestinataireCopie, onDestinataireCopieChange } =
    useDestinataireCopie(chantier, dossier);

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierEauSections} noSaveButton />

      {dossier == null || chantier == null || isChantierLoading || isDossierLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
          <FormSection title="Générer un courrier" size={{ xs: 12, lg: 6 }} container>
            <Grid size="grow">
              <TextField
                label="Copie à"
                value={destinataireCopie ?? ""}
                onChange={onDestinataireCopieChange}
                fullWidth
              />
            </Grid>
            <Grid container spacing={0}>
              {CourrierDefinitions.map((courrierMetaData) => (
                <Grid key={courrierMetaData.type} size={12}>
                  <DocumentGenerationButton
                    courrierMetaData={courrierMetaData}
                    chantier={chantier}
                    dossier={dossier}
                    beforeGeneration={patchDestinataireCopie}
                  />
                </Grid>
              ))}
            </Grid>
          </FormSection>

          <FormSection title="Sharepoint" size={{ xs: 12, lg: 6 }} container>
            <Grid container size={12}>
              <Link href={filesService.getChantierFolderUrl(chantier)} target="_blank">
                Ouvrir le répertoire <b>CHANTIER-{chantier.reference}</b> dans Sharepoint
                <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "top" }} />
              </Link>
            </Grid>
            <Grid container size={12}>
              <Link href={filesService.getDossierFolderUrl(dossier, chantier)} target="_blank">
                Ouvrir le répertoire <b>{dossier.folderName}</b> dans Sharepoint
                <OpenInNewIcon sx={{ fontSize: 10, ml: 0.5, verticalAlign: "top" }} />
              </Link>
            </Grid>
          </FormSection>
        </Grid>
      )}
    </Grid>
  );
}

export const DossierEauFormCourriersWithErrorBoundary =
  withPageErrorBoundary(DossierEauFormCourriers);
