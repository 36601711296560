import { type ReactNode } from "react";
import Grid from "@mui/material/Grid2";
import type { DossierEau } from "models";
import { FormSection } from "components/Layout";
import { withPageErrorBoundary } from "utils/errorHandling";
import { LoadingScreen } from "components/Loading";
import { grey } from "@mui/material/colors";
import { useDossier, useChantier } from "providers";
import DossierSections from "../DossierSections";
import { dossierCipaSections } from "../routes/dossiersSectionsDefinitions";
import { TextField } from "@mui/material";
import { useDestinataireCopie } from "pages/Chantier/hooks/useDestinataireCopie";

function DossierCipaFormCourriers(): ReactNode {
  const { chantier, isLoading: isChantierLoading } = useChantier();
  const { dossier, isLoading: isDossierLoading = false } = useDossier<DossierEau>();
  const { destinataireCopie, onDestinataireCopieChange } = useDestinataireCopie(chantier, dossier);

  return (
    <Grid container>
      <DossierSections isLoading={false} sections={dossierCipaSections} noSaveButton />

      {dossier == null || chantier == null || isChantierLoading || isDossierLoading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2} sx={{ p: 2, background: grey[200] }} size={12}>
          <FormSection title="Générer un courrier" size={{ xs: 12, lg: 6 }} container>
            <Grid size="grow">
              <TextField
                label="Copie à"
                value={destinataireCopie ?? ""}
                onChange={onDestinataireCopieChange}
                fullWidth
              />
            </Grid>
          </FormSection>
        </Grid>
      )}
    </Grid>
  );
}

export const DossierCipaFormCourriersWithErrorBoundary =
  withPageErrorBoundary(DossierCipaFormCourriers);
