import { useEffect, useState } from "react";
import type { ReactNode } from "react";
import { FormControlLabel, FormHelperText, Switch } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ControlledTextField } from "components/Inputs";
import { useController, useFormContext } from "react-hook-form";
import CommuneAutoCompleteWithErrorBoundary from "components/Inputs/CommuneAutoCompleteWithErrorBoundary";
import type { ChantierCreateData } from "models";
import AdressePicker from "./AdressePicker";

interface AdresseFormProps {
  gridSpacing?: number;
}

function AdresseForm({ gridSpacing = 2 }: Readonly<AdresseFormProps>): ReactNode {
  const [isNouvelleAdresse, setIsNouvelleAdresse] = useState<boolean>(false);
  const { control, setValue, trigger } = useFormContext<ChantierCreateData>();
  const {
    fieldState: { error },
  } = useController({
    control,
    name: "adresse",
    rules: {
      validate: (_value) =>
        !isNouvelleAdresse ||
        (_value != null &&
          Object.values(_value).filter((value) => value != null && value !== "").length > 1 &&
          _value?.codeInsee != null) ||
        "L'adresse est obligatoire; au moins la commune et un autre champ doivent être saisis.",
    },
  });
  const {
    fieldState: { error: errorId },
  } = useController({
    control,
    name: "adresseId",
    rules: {
      validate: (_value) =>
        isNouvelleAdresse ||
        (_value != null && _value !== "") ||
        "L'adresse est obligatoire; veuillez sélectionner une adresse existante ou en saisir une nouvelle.",
    },
  });
  useEffect(() => {
    void trigger();
  }, [trigger]);

  return (
    <Grid container alignItems="flex-start" spacing={gridSpacing} size={12}>
      <Grid size={12}>
        <FormControlLabel
          control={
            <Switch
              checked={isNouvelleAdresse}
              onChange={(_, checked) => {
                setIsNouvelleAdresse(checked);
                setValue("adresseId", undefined);
                setValue("adresse", {});
              }}
            />
          }
          label="Saisir l'adresse vous-même"
        />
      </Grid>
      {!isNouvelleAdresse ? (
        <>
          <Grid container spacing={gridSpacing} size={{ xs: 12, md: 6 }}>
            <Grid size={12}>
              <AdressePicker />
            </Grid>
          </Grid>
          {errorId != null && (
            <Grid size={12}>
              <FormHelperText error>{errorId.message}</FormHelperText>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid container spacing={gridSpacing} size={{ xs: 12, md: 6 }}>
            <Grid size={12}>
              <ControlledTextField
                name="adresse.adresseVoie"
                label="Adresse"
                placeholder="3, Rue de la gare"
              />
            </Grid>
            <Grid size={12}>
              <ControlledTextField
                name="adresse.codePostal"
                label="Code Postal"
                placeholder="67000"
              />
            </Grid>
            <Grid size={12}>
              <CommuneAutoCompleteWithErrorBoundary
                onChange={(commune) => {
                  setValue("adresse.libelleCommune", commune?.libelle);
                  setValue("adresse.codeInsee", commune?.id);
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={gridSpacing} size={{ xs: 12, md: 6 }}>
            <Grid size={12}>
              <ControlledTextField name="adresse.lotissement" label="Lotissement" />
            </Grid>
            <Grid size={12}>
              <ControlledTextField
                name="adresse.routeDepartementale"
                label="Route départementale"
              />
            </Grid>
            <Grid size={12}>
              <ControlledTextField name="adresse.parcelle" label="Parcelle" />
            </Grid>
            <Grid size={12}>
              <ControlledTextField name="adresse.lot" label="Lot" />
            </Grid>
            <Grid size={12}>
              <ControlledTextField name="adresse.section" label="Section" />
            </Grid>
          </Grid>
          {error != null && (
            <Grid size={12}>
              <FormHelperText error>{error.message}</FormHelperText>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

export default AdresseForm;
